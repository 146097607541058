var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"body_section"},[_c('div',{staticClass:"modal create_popup login_form",attrs:{"tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalCenterTitle","aria-hidden":"true"},on:{"click":function($event){_vm.errormessage = ''}}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-body"},[_vm._m(0),_c('div',{staticClass:"modal_right padt20"},[_c('div',{staticClass:"modal_right_cnt"},[(_vm.errormessage)?_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errormessage))]):_vm._e(),_c('ValidationObserver',{ref:"setPasswordform",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var handleSubmit = ref.handleSubmit;
var validate = ref.validate;
return [(_vm.username)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Username")]),_c('span',[_vm._v(_vm._s(_vm.username))])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|strongpassword|min:6|max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [((_vm.pshow ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.newPwd),expression:"Payload.newPwd"}],ref:"password",staticClass:"key_icon form-control",attrs:{"solo":"","label":"Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"password","hint":"At least 6 characters","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.Payload.newPwd)?_vm._i(_vm.Payload.newPwd,null)>-1:(_vm.Payload.newPwd)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"change":function($event){var $$a=_vm.Payload.newPwd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.Payload, "newPwd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.Payload, "newPwd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.Payload, "newPwd", $$c)}}}}):((_vm.pshow ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.newPwd),expression:"Payload.newPwd"}],ref:"password",staticClass:"key_icon form-control",attrs:{"solo":"","label":"Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"password","hint":"At least 6 characters","type":"radio"},domProps:{"checked":_vm._q(_vm.Payload.newPwd,null)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"change":function($event){return _vm.$set(_vm.Payload, "newPwd", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.newPwd),expression:"Payload.newPwd"}],ref:"password",staticClass:"key_icon form-control",attrs:{"solo":"","label":"Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"password","hint":"At least 6 characters","type":_vm.pshow ? 'text' : 'password'},domProps:{"value":(_vm.Payload.newPwd)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "newPwd", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form_label"},[_vm._v("Confirm Password")]),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":'required|confirmpassword:@Password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [((_vm.pshow ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.confirmPwd),expression:"Payload.confirmPwd"}],staticClass:"key_icon form-control",attrs:{"solo":"","label":"Confirm Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"CPassword","hint":"At least 6 characters","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.Payload.confirmPwd)?_vm._i(_vm.Payload.confirmPwd,null)>-1:(_vm.Payload.confirmPwd)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"change":function($event){var $$a=_vm.Payload.confirmPwd,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.Payload, "confirmPwd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.Payload, "confirmPwd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.Payload, "confirmPwd", $$c)}}}}):((_vm.pshow ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.confirmPwd),expression:"Payload.confirmPwd"}],staticClass:"key_icon form-control",attrs:{"solo":"","label":"Confirm Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"CPassword","hint":"At least 6 characters","type":"radio"},domProps:{"checked":_vm._q(_vm.Payload.confirmPwd,null)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"change":function($event){return _vm.$set(_vm.Payload, "confirmPwd", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.Payload.confirmPwd),expression:"Payload.confirmPwd"}],staticClass:"key_icon form-control",attrs:{"solo":"","label":"Confirm Password","append-icon":_vm.pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline',"name":"CPassword","hint":"At least 6 characters","type":_vm.pshow ? 'text' : 'password'},domProps:{"value":(_vm.Payload.confirmPwd)},on:{"click":function($event){_vm.erororVisible = false},"click:append":function($event){_vm.pshow = !_vm.pshow;
                            _vm.erororVisible = false;},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setPassword()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.Payload, "confirmPwd", $event.target.value)}}}),_c('span',{staticClass:"v-messages error-msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"primary_btn mart40",attrs:{"disabled":invalid ||
                          !validated ||
                          _vm.Payload.newPwd == '' ||
                          _vm.Payload.confirmPwd != _vm.Payload.newPwd},on:{"click":function($event){_vm.erororVisible = false;
                        validate();
                        handleSubmit(_vm.setPassword);}}},[_vm._v(" Set Password ")])]}}])}),_c('p',{staticClass:"forgot_password center-align"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Login?")])],1)],1)])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal_left"},[_c('div',{staticClass:"modal_title"},[_c('span',{staticClass:"setpassword-logo"},[_c('img',{staticClass:"logonew",attrs:{"src":require("@/assets/images/logo2.png")}})]),_c('h3',[_vm._v("Set Password")])]),_c('figure',[_c('img',{attrs:{"src":require("@/assets/images/client_vector.svg")}})]),_c('span',{staticClass:"userlabel"},[_c('img',{attrs:{"src":require("@/assets/images/user_group.svg")}})])])}]

export { render, staticRenderFns }