const mutations = {
  loading(state, value) {
    state.show_loading = value;
  },
  selectedGlobalClient(state, value) {
    state.selectedGlobalClient = value;
  },
};

export default mutations;
