<template>
  <div class="wrapper">
    <div class="body_section">
      <div
        class="modal create_popup login_form"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        @click="errormessage = ''"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal_left">
                <div class="modal_title">
                  <span v-if="forgot_password == false" class="login-logo"
                    ><img src="@/assets/images/logo2.png"
                  /></span>
                  <h3 v-if="forgot_password == false">Login</h3>
                  <h3 v-else>Forgot password</h3>
                </div>
                <figure><img src="@/assets/images/client_vector.svg" /></figure>
                <span class="userlabel"
                  ><img src="@/assets/images/user_group.svg"
                /></span>
              </div>
              <div class="modal_right padt20" v-if="forgot_password == false">
                <!-- <h2>Login</h2> -->
                <div class="modal_right_cnt">
                  <b-alert show variant="warning" v-if="errormessage">{{
                    errormessage
                  }}</b-alert>
                  <!-- <span class="error" v-if="errormessage">{{errormessage}}</span>  -->
                  <ValidationObserver
                    ref="registerform"
                    v-slot="{ invalid, validated, handleSubmit, validate }"
                  >
                    <div class="form-group">
                      <label class="form_label">Email</label>
                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          solo
                          class="form-control"
                          v-model="loginform.email"
                          label="Email"
                          prepend-inner-icon="mdi-email-outline"
                          @click="erororVisible = false"
                          @keyup.enter="login()"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label class="form_label">Password</label>
                      <ValidationProvider
                        name="Password"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <!--maxlength=10-->
                        <input
                          @click="erororVisible = false"
                          class="key_icon form-control"
                          solo
                          label="Password"
                          v-model="loginform.pwd"
                          :append-icon="
                            pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                          "
                          :type="pshow ? 'text' : 'password'"
                          name="input-10-2"
                          hint="At least 8 characters"
                          @click:append="
                            pshow = !pshow;
                            erororVisible = false;
                          "
                          @keyup.enter="login()"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                    <button
                      class="primary_btn"
                      @click="
                        erororVisible = false;
                        validate();
                        handleSubmit(login);
                      "
                      :disabled="invalid || !validated"
                    >
                      Login
                    </button>
                  </ValidationObserver>
                </div>
                <p
                  class="forgot_password center-align"
                  @click="toogleForgotPassword()"
                >
                  <a href="#">Forgot password ?</a>
                </p>
              </div>

              <div class="modal_right padt20" v-else>
                <div class="forgot_wrap">
                  <b-alert show variant="warning" v-if="errormessage">{{
                    errormessage
                  }}</b-alert>
                  <!-- <span class="error" v-if="errormessage">{{errormessage}}</span> -->
                  <ValidationObserver
                    ref="forgotPasswordform"
                    v-slot="{ invalid, validated, handleSubmit, validate }"
                  >
                    <div class="form-group">
                      <label class="form_label">Email</label>
                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <input
                          type="text"
                          solo
                          class="form-control"
                          v-model="forgotPassword.email"
                          label="Email"
                          prepend-inner-icon="mdi-email-outline"
                          @click="erororVisible = false"
                          @keyup.enter="forgotPasswordAction"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>

                    <button
                      class="primary_btn"
                      @click="
                        erororVisible = false;
                        validate();
                        handleSubmit(forgotPasswordAction);
                      "
                      :disabled="invalid || !validated"
                    >
                      Send
                    </button>
                  </ValidationObserver>
                </div>
                <!-- <span class="error" v-if="errormessage">{{errormessage}}</span>   -->
                <p
                  class="forgot_password center-align"
                  @click="toogleForgotPassword()"
                >
                  <a href="#">Login ?</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import footerView from "@/components/footer.vue"

import { ValidationObserver, ValidationProvider } from "vee-validate";
import _ from "lodash";
export default {
  name: "LNA",
  methods: {
    async forgotPasswordAction() {
      this.erororVisible = false;
      this.errormessage = "";
      this.formSubmited = true;

      const valid = await this.$refs.forgotPasswordform.validate();

      if (valid) {
        this.$store
          .dispatch("auth/resetpassword", this.forgotPassword)
          .then((response) => {
            this.log(response);
            this.forgotPassword.email = "";

            this.notify({
              message: response,
              title: "Success",
              type: "success",
            });
            this.$refs.forgotPasswordform.reset();

            //  this.$router.go('/');
          })
          .catch((response) => {
            //this.notify({message:response ,title:'Error' ,type:"error"});
            this.erororVisible = true;
            this.errormessage = response;
          });
      }

      //this.forgotPassword alert();
    },
    toogleForgotPassword() {
      this.loginform = {
        email: "",
        pwd: "",
      };

      this.forgotPassword.email = "";
      this.erororVisible = false;
      this.errormessage = "";
      if (this.forgot_password == true) {
        this.forgot_password = false;
      } else {
        this.forgot_password = true;
      }

      if (this.$refs.registerform != undefined) {
        this.$refs.registerform.reset();
      }

      if (this.$refs.forgotPasswordform != undefined) {
        this.$refs.forgotPasswordform.reset();
      }
    },
    async login() {
      this.formSubmited = true;
      const valid = await this.$refs.registerform.validate();
      if (valid) {
        this.$store
          .dispatch("auth/login", this.loginform)
          .then((response) => {
            let userData = response["data"]["result"]["data"];

            if (
              _.has(userData, "clientDetails") &&
              userData["roleId"] == 5 &&
              userData["clientDetails"]["statusId"] < 2
            ) {
              this.$router.push({
                name: "questionnaire",
                params: { clientId: userData["clientDetails"]["_id"] },
              });
            } else {
              this.$router.push("/dashboard");
            }
          })
          .catch((response) => {
            // this.notify({message:response ,title:'Error' ,type:"error"});
            this.erororVisible = true;
            this.errormessage = response;
          });
      }
    },
  },
  data() {
    return {
      forgot_password: false,
      pshow: false,
      erororVisible: false,
      errormessage: "",
      loginform: {
        email: "",
        pwd: "",
      },
      forgotPassword: {
        email: "",
      },
      formSubmited: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    // footerView
  },
  props: {
    msg: String,
  },
};
</script>
