import axios from "@/axios.js";
//import store from '@/store'
//import moment from 'moment'

const actions = {
  //masterdata/list
  getMaterData({ commit }, payLoad) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("masterdata/list", payLoad)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  sendInvitation({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("clients/create", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  getClientdetails({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("clients/details", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  //state-registrations/get-credentials
  getCredentials({ commit }, payLoad) {
    //commit("loading" ,true);
    return new Promise((resolve, reject) => {
      axios
        .post("state-registrations/get-credentials", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  saveQuestionnaire({ commit }, payLoad, showLoading = false) {
    if (showLoading) commit("loading", true);

    return new Promise((resolve, reject) => {
      axios
        .post("clients/update", payLoad)
        .then((res) => {
          if (showLoading) commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          if (showLoading) commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  setFilingCharges({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("clients/filingCharges", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  getUsersList({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/list", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  uploadS3File({ commit }, postdata) {
    return new Promise((resolve) => {
      commit("loading", true);
      axios
        .post("/s3/upload", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response);
        })
        .catch((error) => {
          commit("loading", false);
          if (error.response) {
            resolve(error.response.data.result);
          }
        });
    });
  },
  getSignedUrl({ commit }, postdata) {
    console.log(commit);
    return new Promise((resolve) => {
      axios
        .post("s3/get-signed-url", postdata)
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            resolve(error.response.data.result);
          }
        });
    });
  },

  getClientList({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("clients/list", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  addUser({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("users/create", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          console.log("rrrrrrr", err.response.data);
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  updateProfile({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("users/update", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  updateState({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("state-registrations/update-state-matrix", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  updateUserStatus({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("users/update-status", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  changePassword({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("auth/change-password", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          console.log("rrrrrrr", err.response.data);
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  getUserDetails({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/details", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  onAssignClient({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/assign-clients", postdata)
        .then((response) => {
          console.log("responsee", response.data.result);
          commit("loading", false);
          resolve(response);
        })
        .catch((error) => {
          commit("loading", false);
          console.log("reject", error.response.data.result);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  addNote({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/notes/create", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  getNotesList({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/notes/list", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  getTodoList({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/todos/list", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  //notes/details,
  getNoteDetails({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/notes/details", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  upDateNote({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/notes/update", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  getNotifyList({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("notify/list", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  getAssignToList({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/list", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  //path="/transactions/list"
  getList({ commit }, { data, path = "" }) {
    const el = document.body;
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((response) => {
          commit("loading", false);
          document.body.scrollTop = document.documentElement.scrollTop = 0;

          el.classList.remove("hidden");
          resolve(response.data.result);
        })
        .catch((error) => {
          el.classList.remove("hidden");
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  transactionDetails({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/transactions/details", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  getFilingDetails({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/filings/details", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  uploadReturn({ commit }, postdata) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/filings/upload-return", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  globalClient({ commit }, data) {
    //alert(JSON.stringify(data))
    commit("selectedGlobalClient", data);
  },
  removeNotify({ commit }, payLoad) {
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post("/notify/remove", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
  getDashboard({ commit }, postdata) {
    commit("loading", true);
    console.log(postdata);
    return new Promise((resolve, reject) => {
      axios
        .post("/dashboard", postdata)
        .then((response) => {
          commit("loading", false);
          resolve(response.data.result);
        })
        .catch((error) => {
          commit("loading", false);
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  getExportFilePatch({ commit }, { data = {}, path = "/filings/export" }) {
    let userdata = JSON.parse(localStorage.getItem("user"));
    document.cookie = "accessToken=" + userdata["accessToken"];
    console.log(commit);
    commit("loading", true);
    return new Promise((resolve, reject) => {
      axios
        .post(path, data)
        .then((response) => {
          const dt = response.data.result;
          commit("loading", false);
          resolve(dt);
        })
        .catch((error) => {
          commit("loading", false);
          const dt = error.response.data.result;
          reject(dt);
        });
    });
  },

  getTaxWithDate({ commit }, payLoad) {
    //commit("loading" ,true);
    return new Promise((resolve, reject) => {
      axios
        .post("filings/transaction-tax", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },

  generateTaxWithDate({ commit }, payLoad) {
    //commit("loading" ,true);
    return new Promise((resolve, reject) => {
      axios
        .post("filings/tax-generate", payLoad)
        .then((res) => {
          commit("loading", false);
          resolve(res.data.result);
        })
        .catch((err) => {
          commit("loading", false);
          reject(err.response.data.result.error_message.message);
        });
    });
  },
};

export default actions;
