const state = {
  status: "",
  token: localStorage.getItem("token") || "",
  user: JSON.parse(localStorage.getItem("user")) || [],
  userRole: localStorage.getItem("userRole") || "",
  windowWidth: null,
  termsAccepted: localStorage.getItem("termsAccepted") || false,
  pricingModelAccepted: localStorage.getItem("pricingModelAccepted") || false,
};

export default state;
