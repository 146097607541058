import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import ResetPassword from "@/views/reset_password.vue";
import SetPassword from "@/views/set-password.vue";

import store from "@/store";
import axios from "@/axios.js";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/layouts/Default.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: Home,
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        meta: {
          requiresAuth: false,
          title: "Reset Password",
        },
        component: ResetPassword,
      },
      {
        path: "/set-password",
        name: "Set Password",
        meta: {
          requiresAuth: false,
          title: "Set Password",
          //logOutMe:true
        },
        component: SetPassword,
      },
    ],
  },

  //after login
  {
    path: "/",
    component: () => import("@/layouts/Main.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          requiresAuth: true,
          title: "Dashboard",
          hidetopnav: true,
        },
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/client-list",
        name: "client-list",
        meta: {
          requiresAuth: true,
          title: "Client List",
          hidetopnav: true,
        },
        component: () => import("@/views/client-list.vue"),
      },

      //fillings
      {
        path: "/fillings",
        name: "fillings",
        meta: {
          requiresAuth: true,
          title: "Fillings",
          hidetopnav: true,
        },
        component: () => import("@/views/Fillings.vue"),
      },
      //fillings
      {
        path: "/upload-returns/:filingId",
        name: "upload-returns",
        meta: {
          requiresAuth: true,
          title: "Rpload Returns",
          hidetopnav: true,
        },
        component: () => import("@/views/uploadReturns.vue"),
      },
      //transactions
      {
        path: "/transactions",
        name: "transactions",
        meta: {
          requiresAuth: true,
          title: "Transactions",
          hidetopnav: true,
        },
        component: () => import("@/views/Transactions.vue"),
      },

      {
        path: "/todos",
        name: "todos",
        meta: {
          requiresAuth: true,
          title: "Todos",
          hidetopnav: true,
        },
        component: () => import("@/views/todos.vue"),
      },

      //deadlines
      {
        path: "/deadlines",
        name: "deadlines ",
        meta: {
          requiresAuth: true,
          title: "Deadlines",
          hidetopnav: true,
        },
        component: () => import("@/views/Deadlines.vue"),
      },
      //deadlines
      {
        path: "/deadlines",
        name: "deadlines",
        meta: {
          requiresAuth: true,
          title: "Deadlines",
          hidetopnav: true,
        },
        component: () => import("@/views/Deadlines.vue"),
      },
      //Settings
      {
        path: "/settings",
        name: "Settings",
        meta: {
          requiresAuth: true,
          title: "Settings",
          hidetopnav: true,
        },
        component: () => import("@/views/Settings.vue"),
      },

      //Settings
      {
        path: "/notes",
        name: "notes",
        meta: {
          requiresAuth: true,
          title: "Notes",
          hidetopnav: true,
        },
        component: () => import("@/views/Notes.vue"),
      },
      //Add Settings
      {
        path: "/add-note",
        name: "add notes",
        meta: {
          requiresAuth: true,
          title: "Add Notes",
          hidetopnav: true,
        },
        component: () => import("@/views/addNotes.vue"),
      },
      //noteId
      //Add Settings
      {
        path: "/edit-note/:noteId",
        name: "Edit notes",
        meta: {
          requiresAuth: true,
          title: "Edit Notes",
          hidetopnav: true,
        },
        component: () => import("@/views/addNotes.vue"),
      },
      {
        path: "/note-details/:noteId",
        name: "Note Details",
        meta: {
          requiresAuth: true,
          title: "Note Details",
          hidetopnav: true,
        },
        component: () => import("@/views/noteDetails.vue"),
      },
      {
        path: "/filing-details/:filingId",
        name: "Filing Details",
        meta: {
          requiresAuth: true,
          title: "Filing Details",
          hidetopnav: true,
        },
        component: () => import("@/views/DeadlinesDetails.vue"),
      },
      //users
      {
        path: "/users",
        name: "users",
        meta: {
          requiresAuth: true,
          title: "Users",
          hidetopnav: true,
        },
        component: () => import("@/views/Users.vue"),
      },
      {
        path: "/user-details/:id",
        name: "userDetails",
        meta: {
          requiresAuth: true,
          title: "User Details",
          hidetopnav: true,
        },
        component: () => import("@/views/userDetails.vue"),
      },
      {
        path: "/client-details/:clientId",
        name: "client-details",
        meta: {
          requiresAuth: true,
          title: "client Details",
          hidetopnav: true,
        },
        component: () => import("@/views/client-details.vue"),
      },
      {
        path: "/state-details/:clientId",
        name: "state-details",
        meta: {
          requiresAuth: true,
          title: "State Credentials",
          hidetopnav: true,
        },
        component: () => import("@/views/state-credentials.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/questionnaire-layout.vue"),
    children: [
      //questionnaire
      {
        path: "/questionnaire",
        name: "questionnaire",
        meta: {
          requiresAuth: true,
          title: "Questionnaire",
          hidetopnav: true,
        },
        component: () => import("@/views/questionnaire.vue"),
      },
      //clientId
      {
        path: "/questionnaire/:clientId",
        name: "questionnaire",
        meta: {
          requiresAuth: true,
          title: "Questionnaire",
          hidetopnav: true,
        },
        component: () => import("@/views/questionnaire.vue"),
      },
      // {
      //   path: '/client-details/:clientId',
      //   name: 'client-details',
      //   meta: {
      //     requiresAuth: true,
      //     title:'client Details',
      //     hidetopnav:true
      //   },
      //   component: () => import('@/views/client-details.vue')
      // },
      {
        path: "/test",
        name: "test",
        meta: {
          requiresAuth: true,
          title: "test",
          hidetopnav: true,
        },
        component: () => import("@/components/cropimage.vue"),
      },
    ],
  },
];
//questionnaire-layout.vue
const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  //const user = store.getters.getuser;

  if (to.meta.requiresAuth === false) {
    if (_.has(to.meta, "logOutMe") && to.meta["logOutMe"] === true) {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      store.dispatch("auth/logout");
      next();
      return;
    }

    if (
      ["/set-password"].indexOf(to.path) > -1 &&
      store.getters["auth/isLoggedIn"]
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      store.dispatch("auth/logout");
    }
    if (store.getters["auth/isLoggedIn"] && to.path != "/dashboard") {
      next("/dashboard");
      return;
    }
  } else {
    if (store.getters["auth/isLoggedIn"] && to.path == "/") {
      next("/dashboard");
      return;
    }
    if (!store.getters["auth/isLoggedIn"] && to.path != "/") {
      next("/");
      return;
    }
  }
  next();
});

router.afterEach(() => {});
export default router;
