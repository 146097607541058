import axios from "@/axios.js";
//import store from '@/store'
//import moment from 'moment'

const actions = {
  preregister({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/company/pre-register", item)
        .then((response) => {
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },
  register({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/company/register", item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.message);
        });
    });
  },
  activateaccount({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/activate/", item)
        .then((response) => {
          resolve(response.data.result.message);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  requestForReactiveKey({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/users/requestForReactiveKey", item)
        .then((response) => {
          resolve(response.data.result.message);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  login({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/login", item)
        .then((response) => {
          //console.log(JSON.stringify(response));
          //alert(JSON.stringify( response.data.result.data.photo));

          localStorage.setItem("userRole", response.data.result.data.roleId);
          localStorage.setItem("token", response.data.result.data.accessToken);
          localStorage.setItem(
            "user",
            JSON.stringify(response.data.result.data)
          );
          // axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.result.data.accessToken}`

          const user = response.data.result.data;
          commit("auth_success", user);

          resolve(response);
        })
        .catch((error) => {
          //alert(JSON.stringify(error.response.data.result.error));
          reject(error.response.data.result.error_message.error);
        });
    });
  },
  updateuserData({ commit }, user) {
    localStorage.setItem("userRole", user.roleId);
    localStorage.setItem("token", user.accessToken);
    localStorage.setItem("user", JSON.stringify(user));
    commit("auth_success", user);
  },

  resetpassword({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/forgot-password", item)
        .then((response) => {
          resolve(response.data.result.message);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  updateTermsAndCondation({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/clients/termsAndConditions", item)
        .then((response) => {
          commit("updatetermsAccepted", item.termsAccepted);
          commit("updatepricingModalAccepted", item.termsAccepted);
          resolve(response.data.result.message);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  //clients/get-terms-and-pricing-content
  getTermsAndPricingContent({ commit }) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/clients/get-terms-and-pricing-content")
        .then((response) => {
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  getpricingModel({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/clients/termsAndConditions", item)
        .then((response) => {
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },
  setforgotpassword({ commit }, item) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/update-password", item)
        .then((response) => {
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve) => {
      console.log(commit);
      commit("logout");
      localStorage.removeItem("token");
      localStorage.removeItem("selectedGlobalClient");
      localStorage.removeItem("userRole");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      resolve();
    });
  },

  //auth/get-token-details
  getTokenDetails({ commit }, payLoad) {
    console.log(commit);
    return new Promise((resolve, reject) => {
      axios
        .post("/auth/get-token-details", payLoad)
        .then((response) => {
          resolve(response.data.result);
        })
        .catch((error) => {
          reject(error.response.data.result.error_message.message);
        });
    });
  },
};

export default actions;
