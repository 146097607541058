<template>
  <div class="home">
    <div class="wrapper">
      <div class="body_section">
        <div
          class="modal create_popup login_form"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          @click="errormessage = ''"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="modal_left">
                  <div class="modal_title">
                    <span class="setpassword-logo">
                      <img class="logonew" src="@/assets/images/logo2.png" />
                    </span>
                    <h3>Set Password</h3>
                  </div>
                  <figure>
                    <img src="@/assets/images/client_vector.svg" />
                  </figure>
                  <span class="userlabel"
                    ><img src="@/assets/images/user_group.svg"
                  /></span>
                </div>
                <div class="modal_right padt20">
                  <div class="modal_right_cnt">
                    <b-alert show variant="warning" v-if="errormessage">{{
                      errormessage
                    }}</b-alert>
                    <ValidationObserver
                      ref="setPasswordform"
                      v-slot="{ invalid, validated, handleSubmit, validate }"
                    >
                      <div class="form-group" v-if="username">
                        <label class="form_label">Username</label>
                        <span>{{ username }}</span>
                      </div>
                      <div class="form-group">
                        <label class="form_label">Password</label>
                        <ValidationProvider
                          name="Password"
                          rules="required|strongpassword|min:6|max:30"
                          v-slot="{ errors }"
                        >
                          <input
                            @click="erororVisible = false"
                            class="key_icon form-control"
                            solo
                            label="Password"
                            v-model="Payload.newPwd"
                            :append-icon="
                              pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                            "
                            :type="pshow ? 'text' : 'password'"
                            name="password"
                            ref="password"
                            hint="At least 6 characters"
                            @click:append="
                              pshow = !pshow;
                              erororVisible = false;
                            "
                            @keyup.enter="setPassword()"
                          />
                          <span class="v-messages error-msg">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                      <div class="form-group">
                        <label class="form_label">Confirm Password</label>
                        <ValidationProvider
                          name="Confirm Password"
                          :rules="'required|confirmpassword:@Password'"
                          v-slot="{ errors }"
                        >
                          <input
                            @click="erororVisible = false"
                            class="key_icon form-control"
                            solo
                            label="Confirm Password"
                            v-model="Payload.confirmPwd"
                            :append-icon="
                              pshow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                            "
                            :type="pshow ? 'text' : 'password'"
                            name="CPassword"
                            hint="At least 6 characters"
                            @click:append="
                              pshow = !pshow;
                              erororVisible = false;
                            "
                            @keyup.enter="setPassword()"
                          />
                          <span class="v-messages error-msg">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>

                      <button
                        class="primary_btn mart40"
                        @click="
                          erororVisible = false;
                          validate();
                          handleSubmit(setPassword);
                        "
                        :disabled="
                          invalid ||
                            !validated ||
                            Payload.newPwd == '' ||
                            Payload.confirmPwd != Payload.newPwd
                        "
                      >
                        Set Password
                      </button>
                    </ValidationObserver>
                    <!-- <span class="error" v-if="errormessage">{{errormessage}}</span>   -->
                    <p class="forgot_password center-align">
                      <router-link to="/">Login?</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import footerView from "@/components/footer.vue"

import { ValidationObserver, ValidationProvider } from "vee-validate";
import _ from "lodash";
export default {
  name: "SetPassword",
  mounted() {
    try {
      this.Payload["pin"] = this.$route.query["key"];
      this.Payload["userId"] = this.$route.query["userId"];
      this.$store
        .dispatch("auth/getTokenDetails", { pin: this.Payload["pin"] })
        .then((rx) => {
          console.log(rx);
          this.tokenDetails = rx;
          this.username = this.$route.query["email"] || null; // Set username as the email from tokenDetails
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      this.log(err);
    }
  },
  methods: {
    goToDashBoard() {
      this.$router.go("/");
    },
    async setPassword() {
      this.formSubmited = true;
      const valid = await this.$refs.setPasswordform.validate();
      if (valid) {
        this.$store
          .dispatch("auth/setforgotpassword", this.Payload)
          .then((response) => {
            //  alert(JSON.stringify(response));
            // this.log( response);
            if (_.has(response, "email")) {
              this.notify({
                message: response.message,
                title: "Success",
                type: "success",
              });
              this.$store
                .dispatch("auth/login", {
                  email: response.email,
                  pwd: this.Payload["newPwd"],
                })
                .then((response) => {
                  this.log(response);
                  this.$router.push("/dashboard");
                })
                .catch((err) => {
                  this.log(err);
                  this.$router.push("/");
                });
            } else {
              this.notify({
                message: response.message,
                title: "Success",
                type: "success",
              });

              //this.$router.go('/dashboard');
              setTimeout(() => {
                this.$router.push("/");
              }, 1000);
            }

            //
          })
          .catch((response) => {
            this.notify({ message: response, title: "Error", type: "error" });
            this.erororVisible = true;
            this.errormessage = response;
          });
      }
    },
  },
  data() {
    return {
      tokenDetails: null,
      forgot_password: false,
      pshow: false,
      erororVisible: false,
      errormessage: "",
      username: "", // Add username data property
      Payload: {
        pin: "",
        action: "set-password",
        newPwd: "",
        confirmPwd: "",
        userId: "",
      },
      formSubmited: false,
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    // footerView
  },
  props: {
    msg: String,
  },
};
</script>