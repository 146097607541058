import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import BugsnagPerformance from "@bugsnag/browser-performance";

if (process.env.VUE_APP_BUGSNAG_ENABLED === "true") {
  console.log("Bugnsag initiated");
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_APIKEY,
    plugins: [new BugsnagPluginVue()],
  });
  BugsnagPerformance.start({ apiKey: process.env.VUE_APP_BUGSNAG_APIKEY });
  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./vee-validate";
import "./plugins/filters.js";
import "vue-advanced-cropper/dist/style.css";

// Vuesax Component Framework
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css' // Vuesax
// import 'material-icons/iconfont/material-icons.css' //Material Icons
// //Vue.use(Vuesax)

import "./assets/public/css/bootstrap.min.css";
import "./assets/public/css/bootstrap-select.min.css";
import "./assets/public/sass/style.scss";

// import "./assets/js/jquery_3.2.1_/jquery.min.js"
import "jquery";
// import "./assets/js/50d85a59fb.js"
// import "./assets/js/bootstrap.min.js"
import "bootstrap";
// import "./assets/js/bootstrap-select.min.js"
import "bootstrap-select";
//import "./assets/js/jquery_3.2.1_/custom.js"

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "vue-multiselect-inv/dist/vue-multiselect.min.css";

import optimaMixin from "./plugins/mixins.js";
import VueSlimScroll from "vue-slimscroll";
import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";

Vue.use(MonthPicker);
Vue.use(MonthPickerInput);

Vue.mixin(optimaMixin);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueSlimScroll);
import Vue2Filters from "vue2-filters";

Vue.use(Vue2Filters);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
