import _ from "lodash";
const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, token) {
    state.status = "success";
    state.token = token.accessToken;
    state.user = token;
    state.userRole = token.roleId;
    localStorage.setItem("termsAccepted", true);
    localStorage.setItem("pricingModelAccepted", true);
    state.termsAccepted = true;
    state.pricingModelAccepted = true;

    if (_.has(token, "clientDetails") && token.roleId == 5) {
      if (_.has(token["clientDetails"], "termsAccepted")) {
        state.termsAccepted = token["clientDetails"]["termsAccepted"];
        state.pricingModelAccepted =
          token["clientDetails"]["pricingModelAccepted"];

        localStorage.setItem(
          "termsAccepted",
          token["clientDetails"]["termsAccepted"]
        );
        localStorage.setItem(
          "pricingModelAccepted",
          token["clientDetails"]["pricingModelAccepted"]
        );
      }
    } else {
      localStorage.setItem("termsAccepted", true);
      localStorage.setItem("pricingModelAccepted", true);
      state.termsAccepted = true;
      state.pricingModelAccepted = true;
    }
  },
  auth_error(state) {
    state.status = "error";
  },
  updatetermsAccepted(state, value) {
    state.termsAccepted = value;
    localStorage.removeItem("termsAccepted", value);
  },

  updatepricingModalAccepted(state, value) {
    state.pricingModelAccepted = value;
    localStorage.removeItem("pricingModelAccepted", value);
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.termsAccepted = true;
  },
};

export default mutations;
