const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  getuser: (state) => state.user,
  getuserRole: (state) => state.userRole,
  getclientAgrementStatus: (state) => state.termsAccepted,
  getpricingModelAccepted: (state) => state.pricingModelAccepted,
};

export default getters;
